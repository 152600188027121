#logo {
    height: 40px;
    margin: 16px;
    display: flex;
}

#logo-text {
    font-size: 20px;
    color: white;
    width: 200px;
    height: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    line-height: 100px;

}

